.articlePost {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    min-width: 480px;
    max-width: 480px;

    @media (max-width: 599px) {
        min-width: 100%;
        max-width: 100%;
    }

    .postContent {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        width: 100%;
    }

    .image {
        min-width: 180px;
        min-height: 100px;
        max-width: 180px;
        max-height: 100px;

        img {
            object-fit: cover;
            object-position: top left;
        }
    }

    &.dynamic {
        min-width: 100%;
        max-width: 100%;

        .postContent {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            row-gap: 12px;
        }

        .image {
            min-width: unset;
            min-height: unset;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &.latest {
        width: 100%;
        height: 100%;
        min-width: unset;
        max-width: unset;

        .postContent {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            row-gap: 24px;
        }

        .image {
            width: 100%;
            height: 100%;
            min-width: unset;
            min-height: unset;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 4px;
    width: 100%;

    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        row-gap: 4px;

        .date {
            color: var(--c-contrast-3);
            font-weight: bold;
            font-size: 14px;
        }

        .title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            color: var(--c-fg);
            text-align: left;
            word-wrap: break-word;
            word-break: break-word;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 48px;
        }
    }

    .content {
        color: var(--c-contrast-3);
    }

    .info {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.5;
        color: var(--c-contrast-4);

        .category {
            color: var(--c-primary);
            width: min-content;
            border-bottom: 1px solid transparent;
            white-space: nowrap;
            cursor: pointer;

            @media (any-hover: hover) {
                &:hover {
                    border-bottom: 1px solid var(--c-primary);
                }
            }
        }
    }

    &.large {
        row-gap: 12px;

        .wrap {
            row-gap: 12px;

            .title {
                font-size: 24px;
                -webkit-line-clamp: unset;
                height: unset;
            }
        }
    }
}
