.categorizedPosts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: clamp(40px, 4vw, 80px) 20px;

    @media (max-width: 1199px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 599px) {
        grid-template-columns: 1fr;
    }

    .column {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 16px;
            font-weight: bold;
            color: var(--c-contrast-4);
            margin-bottom: 24px;
            width: min-content;
            border-bottom: 1px solid transparent;
            white-space: nowrap;

            @media (any-hover: hover) {
                &:hover {
                    border-bottom: 1px solid var(--c-contrast-4);
                }
            }
        }

        .posts {
            display: flex;
            flex-direction: column;
            row-gap: 48px;
        }

        .viewMore {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            width: min-content;
            border-bottom: 1px solid transparent;
            white-space: nowrap;
            font-weight: bold;

            .icon {
                min-width: 10px;
                width: 10px;
                max-width: 10px;
                transition: background-color 0.2s ease;
            }

            @media (any-hover: hover) {
                &:hover {
                    border-bottom: 1px solid var(--c-contrast-4);
                }
            }
        }
    }

    .skeleton {
        width: 480px;
        height: 100px;

        @media (max-width: 1199px) {
            width: 100%;
            height: 16vw;
        }

        @media (max-width: 1023px) {
            height: 25vw;
        }

        @media (max-width: 599px) {
            height: 50vw;
        }
    }
}
