.journalPage {
    text-align: center;
    padding: min(100px, var(--p-page-top)) 0;

    .container {
        padding: 0 var(--p-page-sides);
    }

    .title {
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size: 16vw;
        padding: 0 40px;
        overflow: hidden;
        font-family: var(--f-primary);
        font-weight: bold;
        color: var(--c-contrast-6);
    }

    .pages {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px 56px;
        margin-bottom: 40px;

        .option {
            font-weight: bold;
            font-size: 14px;
            color: var(--c-contrast-3);
            line-height: 1;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;

            &.selected {
                border-bottom: 1px solid var(--c-contrast-5);
                color: var(--c-contrast-5);
            }

            @media (any-hover: hover) {
                &:hover {
                    border-bottom: 1px solid var(--c-contrast-3);
                }
            }
        }
    }

    .posts {
        display: grid;
        width: 100%;
        row-gap: 48px;
        grid-template-columns: 1fr;

        .latest {
            display: flex;
            flex-direction: column;
        }

        .latestPosts {
            display: grid;
            grid-gap: clamp(40px, 4vw, 80px) 20px;
            grid-template-columns: repeat(2, 1fr);

            @media (max-width: 599px) {
                grid-template-columns: 1fr;
            }

            .skeleton {
                width: 100%;
                height: 25vw;
                min-width: 100%;
                max-width: 100%;

                @media (min-width: 1024px) {
                    height: 100px;
                    min-width: 480px;
                    max-width: 480px;
                }

                @media (max-width: 599px) {
                    height: 50vw;
                }
            }
        }

        &.row {
            grid-template-columns: 2fr 1fr;
            column-gap: 4vw;

            @media (min-width: 1300px) {
                column-gap: 120px;
            }

            .latestPosts {
                display: flex;
                flex-direction: column;
                row-gap: 48px;
            }
        }
    }

    .subscribe {
        margin: 56px 0;
        background-color: var(--c-contrast-1);
        padding: clamp(20px, 5vw, 72px);
        border-radius: 24px;
    }
}
