.journalPostPage {
    text-align: center;
    padding: min(100px, var(--p-page-top)) 0;

    .container {
        padding: 0 var(--p-page-sides);
    }

    .separator {
        margin: 32px 0;
        width: 100%;

        @media (min-width: 1024px) {
            margin: 32px 80px;
            width: calc(100% - 160px);
        }
    }

    .tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px 24px;
        margin-bottom: 28px;

        .tag {
            width: fit-content;
        }
    }

    .articlePost {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;

        .postContent {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            row-gap: 24px;
        }

        .image {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    }

    .tldr {
        display: flex;
        flex-direction: column;
        background-color: var(--c-contrast-1);
        border-radius: 8px;
        padding: 24px;
        font-weight: normal;
        line-height: 2;
        margin-bottom: 32px;

        ul {
            margin-bottom: 0;
        }

        p,
        td,
        li,
        a {
            font-size: 14px;
        }

        h2 {
            margin-bottom: 16px;
        }

        li {
            margin-left: 20px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: flex-start;
        align-items: center;
    }

    .text {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 100%;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            row-gap: 12px;

            .topRow {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 12px;
            }

            .date {
                color: var(--c-contrast-3);
                font-weight: bold;
                font-size: 14px;
            }

            .title {
                line-height: 1.5;
                color: var(--c-fg);
                text-align: left;
            }
        }

        .infoWrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;
            align-items: center;

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .info {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            color: var(--c-contrast-4);

            .category {
                color: var(--c-primary);
                width: min-content;
                border-bottom: 1px solid transparent;
                white-space: nowrap;
                cursor: pointer;

                @media (any-hover: hover) {
                    &:hover {
                        border-bottom: 1px solid var(--c-primary);
                    }
                }
            }
        }
    }
}
