.otherPostsBatches {
    display: grid;
    grid-gap: clamp(40px, 4vw, 80px) 20px;
    grid-template-columns: 1fr;
}

.otherPosts {
    display: grid;
    grid-gap: clamp(40px, 4vw, 80px) 20px;
    grid-template-columns: 1fr;

    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }

    .skeleton {
        width: 100%;
        height: 50vw;

        @media (min-width: 600px) {
            height: 25vw;
        }

        @media (min-width: 768px) {
            height: 16vw;
        }

        @media (min-width: 1024px) {
            height: 12vw;
        }
    }
}
